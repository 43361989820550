import React from 'react'

import { DownloadAppStore, DownloadGooglePlay } from '../../../../utils/icons'

import styles from './DownloadButtons.module.css'
import { APP_STORE_LINKS } from '../../../../utils/constants'
import { getPlatform } from '../../../../utils'

const DownloadButtons = () => {
  const handleRedirect = (isAppStore = false) => {
    const platform = getPlatform()
    const isPhone = platform === 'ios' || platform === 'android'
    if (isPhone) {
      window.location.href = 'app.padel.padel://'
    }

    // Set a timeout to check if we're still on the same page
    setTimeout(
      () => {
        if (document.hidden) {
          return
        }
        if (isAppStore) {
          return (window.location.href = APP_STORE_LINKS.IOS)
        }
        window.location.href = APP_STORE_LINKS.ANDROID
      },
      isPhone ? 2000 : 0,
    )
  }
  return (
    <div className={styles.downloadButtons}>
      <DownloadAppStore onClick={() => handleRedirect(true)} />
      <DownloadGooglePlay onClick={() => handleRedirect(false)} />
    </div>
  )
}

export default DownloadButtons
